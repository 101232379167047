import { useState, useEffect } from "react";
import { Box, TextareaAutosize, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from 'js-cookie';
import config from "../config";

const TTSInput = () => {
  const [text, setText] = useState("");
  const [voice, setVoice] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [model, setModel] = useState("");

  // Mock voices - replace with actual voices from your TTS service
  const voices = [
    { id: "JuY6YJGWzsmuwCg8CV5c", name: "Eric Norland" },
    { id: "uyVNoMrnUku1dZyVEXwD", name: "Anna Kim" },
    { id: "Xb7hH8MSUJpSbSDYk0k2", name: "Alice" },
    { id: "nPczCjzI2devNBz1zQrb", name: "Brian" },
    { id: "FGY2WhTYpPnrIDTdsKH5", name: "Laura" },
    { id: "JBFqnCBsd6RMkjVDRZzb", name: "George" },
    { id: "WqVy7827vjE2r3jWvbnP", name: "Hyuk" },
  ];

  // Mock models - replace with actual models from your TTS service
  const models = [
    { id: "eleven_multilingual_v2", name: "다국어 v2 (추천)" },
    { id: "eleven_turbo_v2_5", name: "터보 v2.5" },
    { id: "eleven_turbo_v2", name: "터보 v2" },
    { id: "eleven_monolingual_v1", name: "영어 v1" },
    { id: "eleven_multilingual_v1", name: "다국어 v1" },
  ];

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    setVoice(e.target.value);
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handlePlay = async () => {
    try {
      setIsLoading(true);
      setError(null);
      console.log("API 요청 중...");
      const accessKey = Cookies.get('__k9x_s3c_h4sh');
      if (!accessKey) {
        throw new Error('액세스 키가 필요합니다');
      }
      const response = await fetch(`${config.apiUrl}/dubbing`, {
        method: "POST",
        body: JSON.stringify({ 
          text, 
          voice_id: voice,
          model,
          key: accessKey 
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("API 응답:", data);
      
      if (!data.success) {
        if (data.error === 'Key is required') {
          throw new Error('액세스 키가 필요합니다');
        } else if (data.error === 'Invalid key') {
          throw new Error('유효하지 않은 액세스 키입니다');
        } else if (data.error === 'Key is not active') {
          throw new Error('비활성화된 액세스 키입니다');
        } else if (data.error === 'Key has reached the limit of 1000 dubbings or 100,000 characters') {
          throw new Error('액세스 키의 사용 한도를 초과했습니다');
        } else {
          throw new Error(data.error || '오디오 생성에 실패했습니다');
        }
      }

      // Verify the URL
      console.log("오디오 URL 수신:", data.url);
      
      // Test the URL directly
      const audioTest = new Audio();
      audioTest.onerror = (e) => {
        console.error("오디오 로드 에러:", e);
        setError("오디오 파일 로드에 실패했습니다");
      };
      audioTest.onloadeddata = () => {
        console.log("오디오 로드 성공");
        setAudioUrl(data.url);
      };
      audioTest.src = data.url;

    } catch (error) {
      console.error("재생 중 오류:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (audioUrl) {
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = "음성파일.mp3";
      link.click();
    }
  };

  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const isGenerateDisabled = !text || !voice || !model || isLoading;

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 3 }}>
      <Box sx={{ 
        width: "100%", 
        marginBottom: 2,
        '& .MuiTextarea-root': {
          width: '100% !important',
          boxSizing: 'border-box'
        }
      }}>
        <TextareaAutosize
          minRows={4}
          placeholder="음성으로 변환할 텍스트를 입력하세요..."
          style={{
            width: "100%",
            padding: "12px",
            borderRadius: "4px",
            borderColor: "#ccc",
            boxSizing: "border-box"
          }}
          value={text}
          onChange={handleTextChange}
        />
      </Box>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>음성 선택</InputLabel>
        <Select value={voice} onChange={handleVoiceChange} label="음성 선택">
          {voices.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>모델 선택</InputLabel>
        <Select value={model} onChange={handleModelChange} label="모델 선택">
          {models.map((m) => (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="contained" startIcon={<PlayArrowIcon />} onClick={handlePlay} disabled={isGenerateDisabled}>
          {isLoading ? '생성 중...' : '음성 생성'}
        </Button>
        <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownload} disabled={!audioUrl}>
          다운로드
        </Button>
      </Box>

      {audioUrl && (
        <div style={{ marginTop: '20px' }}>
          <audio 
            controls 
            key={audioUrl}
            preload="auto"
            onError={(e) => {
              console.error("오디오 플레이어 에러:", e);
              setError("오디오 재생에 실패했습니다");
            }}
            onLoadedData={() => console.log("오디오 데이터 로드됨")}
            onCanPlay={() => console.log("오디오 재생 가능")}
          >
            <source 
              src={audioUrl} 
              type="audio/mpeg"
              onError={(e) => console.error("소스 에러:", e)}
            />
            브라우저가 오디오 요소를 지원하지 않습니다.
          </audio>
        </div>
      )}

      {error && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          오류: {error}
        </div>
      )}
    </Box>
  );
};

export default TTSInput;
