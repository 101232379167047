const env = "production"; // "development" or "production"

const devConfig = {
  apiUrl: "http://localhost:8080",
  environment: "development"
};

const prodConfig = {
  apiUrl: "https://dubbing-website-api-28663519391.asia-northeast3.run.app", 
  environment: "production"
};

// Export the appropriate config
const config = env === "development" ? devConfig : prodConfig;

export default config;
